import { Alert, Button, Col, Divider, Form, Input, message, Row } from "antd";
import { motion } from "motion/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { apiPerformRecovery, apiRequestRecovery } from "../../../api/resetPassword";
import logo from "../../../assets/images/logo.png";
import "./NewPassword.scss";

const NewPassword = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [codeError, setCodeError] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkCodeComplete = () => {
    return code.every((digit) => digit.length === 1);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep < 1) {
          const payloadEmail = {
            email: form.getFieldValue('email')
          }
          apiRequestRecovery(payloadEmail).then((response) => {
            if (response.status === 'success') {
              setCurrentStep(1);
            }
          })
        } else if (currentStep === 1) {
          if (code.join('').length === 6) {
            setCurrentStep(2);
            setErrorStatus(null);
          } else {
            setCodeError(true);
          }
        } else if (currentStep === 2) {
          setIsLoading(true)
          const payloadPassword = {
            new_password: form.getFieldValue('password'),
            token: parseInt(code.join(''), 10)
          }

          apiPerformRecovery(payloadPassword)
            .then((response) => {
              if (response.status === 'success') {
                setIsLoading(false)
                navigate("/login")
                message.success("Senha atualizada com sucesso!");
              } else {
                setIsLoading(false)
                setErrorStatus(true);
                setCurrentStep(1);
              }
            })
        }

      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    let newCode = [...code];
    newCode[index] = value;
    if (value.length === 1 && index < 5) {
      document.getElementById(`input-${index + 1}`).focus();
    }

    setCode(newCode);
    if (checkCodeComplete()) {
      setCodeError(false);
    }
  };

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("Text");
    if (pastedCode.length === 6) {
      setCode(pastedCode.split(""));
    }
  };

  return (
    <div className={"NewPassword"}>
      <Helmet>
        <title>Clap Tickets - Recuperação de Senha</title>
      </Helmet>
      <motion.div
        className="form-container"
        initial={{ y: "100vw", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: "-100vw", opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div className={"logo"} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
          <img src={logo} alt="Logo" />
          <span>Recuperação de Senha</span>
        </div>
        <Form
          form={form}
          layout="vertical"
          className="login-form"
          initialValues={{ remember: true }}
        >
          {currentStep === 0 && (
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Por favor, insira seu email!" },
                { type: "text", message: "email inválido!" },
              ]}
            >
              <Input
                placeholder="Email"
                size="small"
                type="email"
              />
            </Form.Item>
          )}
          {currentStep === 1 && (
            <Form.Item
              label="Código de Recuperação"

              validateStatus={codeError ? "error" : ""}
              help={codeError ? "Por favor, preencha todos os campos!" : ""}
            >
              <p>Insira o código de recuperação enviado para o email: <strong>{form.getFieldValue('email')}</strong></p>
              <Row gutter={8}>
                {Array.from({ length: 6 }).map((_, index) => (
                  <Col key={index} span={4}>
                    <Input
                      id={`input-${index}`}
                      value={code[index]}
                      onChange={(e) => handleInputChange(e, index)}
                      onPaste={handlePaste}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        width: "50px",
                        borderColor: codeError && !code[index] ? "red" : "",
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Item>
          )}
          {currentStep === 2 && (
            <>
              <Form.Item
                name="password"
                label="Nova Senha"
                rules={[
                  { required: true, message: 'Por favor, insira sua senha!' },
                  {
                    min: 8,
                    message: "A senha deve ter pelo menos 8 caracteres",
                  },
                  {
                    pattern:
                      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                    message:
                      "A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial",
                  }
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Nova Senha" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirmar Senha"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Por favor, confirme sua senha!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("As senhas não coincidem!")
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Confirmar Senha" />
              </Form.Item>
            </>
          )}
        </Form>
        {errorStatus && (
          <Alert
            message={"Erro na recuperação. Verifique seu código de recuperação."}
            type="error"
            closable={false}
            style={{ marginBottom: 16 }}
          />
        )}
        <div className="container-buttons">
          <Button
            size="large"
            className="login-form-button"
            type="primary"
            loading={isLoading}
            onClick={handleNext}
          >
            {(() => {
              switch (currentStep) {
                case 0:
                  return 'Resetar Senha';
                case 1:
                  return 'Recuperar Senha';
                default:
                  return 'Finalizar';
              }
            })()}
          </Button>

        </div>

        <Divider />
        <div className={"register"}>
          <Button
            type="text"
            size="small"
          >
            Lembrou da senha?
          </Button>
          <Button size="small" onClick={() => navigate("/login")}>
            Entre agora!
          </Button>
        </div>
      </motion.div>
      <div className={"background"}>
        <span>Com Clap, seu ingresso está na palma da mão</span>
      </div>
    </div>
  );
};

export default NewPassword;
