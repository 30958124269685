import { baseSiteEndPoint, buildHeaders, clearStorageExceptSeller } from "./common";

export async function login(username, password) {
  const response = await fetch(`${baseSiteEndPoint}/user/login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const error = {
      status: response.status,
      message: errorResponse || response.statusText || response.message,
    };
    throw error;
  }

  const data = await response.json();
  // Armazena os tokens e outras informações no localStorage
  localStorage.setItem("accessToken", data.access);
  localStorage.setItem("refreshToken", data.refresh);
  localStorage.setItem(
    "accessTokenRenewInterval",
    data.access_token_renew_interval
  );
  localStorage.setItem("userInfo", JSON.stringify(data.user));
  return data;
}

export async function getAccess(userID) {
  const response = await authorizedFetch(
    `${baseSiteEndPoint}/user/${userID}/legacy/accesses/`,
    {
      method: "GET",
      headers: buildHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Token refresh failed");
  }
  const data = await response.json();
  if (data.results) {
    data.results = data.results.filter((result) => {
      if (result.account) { // only allow access of accounts
        return result.account.type.includes("SUB") ; // only allow sub accounts
      }
      return false
    });
    data.count = data.results.length;
  }
  return data;
}

export async function apiRefreshLogin(refreshToken) {
  const refresh = localStorage.getItem("refreshToken");
  if (!refresh) {
    throw new Error("No refresh token available");
  }

  const response = await fetch(`${baseSiteEndPoint}/user/login/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refresh,
    }),
  });

  if (!response.ok) {
    throw new Error("Token refresh failed");
  }

  const data = await response.json();
  // Atualiza o token de acesso no localStorage
  localStorage.setItem("accessToken", data.access);
  return data;
}

// Função para verificar se o token ainda é válido
export const tokenStillValid = () => {
  if (hasToken()) {
    const expireTime = getItemFromStorage("accessTokenRenewInterval");
    return expireTime > new Date().getTime();
  }
};

function hasToken() {
  return !!localStorage.getItem("accessToken");
}

export const getToken = () => {
  return getItemFromStorage("accessToken");
};

export const getRefreshToken = () => {
  return getItemFromStorage("refreshToken");
};

// Função para obter item do storage
function getItemFromStorage(key) {
  return localStorage.getItem(key);
}

// Função para atualizar o token
function updateToken(data) {
  localStorage.setItem("accessToken", data.access);
  localStorage.setItem("refreshToken", data.refresh);
  localStorage.setItem(
    "accessTokenRenewInterval",
    new Date().getTime() + (data.access_token_renew_interval * 1000)
  );
}

function logoutUser() {
  clearStorageExceptSeller()
}

let refreshPromise = undefined;
export const authorizedFetch = (url, init = {}) => {
  return new Promise((resolve, reject) => {
    const accessToken = getItemFromStorage("accessToken");
    const refreshToken = getItemFromStorage("refreshToken");

    if (accessToken) {
      if (!init.headers) {
        init.headers = {};
      }

      if (!tokenStillValid()) {
        if (!refreshPromise) {
          refreshPromise = apiRefreshLogin(refreshToken)
            .then((data) => {
              updateToken(data);
              refreshPromise = undefined;
            })
            .catch(() => {
              clearStorageExceptSeller();
              refreshPromise = undefined;
              reject(new Error("Refresh token failed"));
            });
        }

        return refreshPromise.then(() => {
          init.headers["Authorization"] = `Bearer ${getToken()}`;
          return fetch(url, init).then(resolve).catch(reject);
        });
      }

      init.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorResponse) => {
            const error = {
              status: response.status,
              message: errorResponse || response.statusText || response.message,
            };

            if (error.status === 401 || error.status === 403) {
              logoutUser();
            }
            reject(error);
          });
        }
        resolve(response);
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          logoutUser();
        }
        reject(error);
      });
  });
};
