import { CalendarOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, message, Modal, Radio, Tooltip } from 'antd';
import ptBR from "antd/lib/locale/pt_BR";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiBankSlip } from '../../../api/transfer';
import { ReactComponent as Check } from '../../../assets/images/transfer/Check.svg';
import { ReactComponent as Clock } from '../../../assets/images/transfer/ClockCircle.svg';
import { ReactComponent as Ellipsis } from '../../../assets/images/transfer/Ellipsis.svg';
import { ReactComponent as Success } from '../../../assets/images/transfer/Success.svg';
import { formatPrice, formatPriceInCents } from '../../../utils/format';
import './TransferModalBoleto.scss';

const TransferModalBoleto = ({ visible, onClose, bankBalance }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [transferOption, setTransferOption] = useState('agendar');
  const [businessDays, setBusinessDays] = useState([]);
  const [errors, setErrors] = useState({});
  const [priceTransfer, setPriceTransfer] = useState(0);
  const [internalCurrentStep, setInternalCurrentStep] = useState(0);
  const [dayTransfer, setDayTransfer] = useState(null);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCurrentStep(0);
      setInternalCurrentStep(0);
      setPriceTransfer(0);
    }
  }, [visible]);

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep < 1 && internalCurrentStep === 0) {
          setInternalCurrentStep(1);
        } else if (currentStep === 0 && internalCurrentStep === 1) {
          if (priceTransfer >= 500) {
            setCurrentStep(1);
            setInternalCurrentStep(0);
          } else {
            setErrors({ amount: "Por favor, insira um valor maior que R$ 5,00." });
          }
        } else if (currentStep === 1 && internalCurrentStep === 0) {
          setCurrentStep(2);
        }
        else if (currentStep === 2 && internalCurrentStep === 0) {
          const payload = {
            bank_slip_code: inputCode,
            target_date: form.getFieldValue('dateTransfer'),
            amount: priceTransfer
          }
          apiBankSlip(payload)
            .then((response) => {
              setSuccessModal(true);
            })
            .catch((error) => {
              message.error("Erro ao realizar transferência. Verifique os dados e tente novamente.");
            });
        }
      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  };

  const handleConfirmOK = () => {
    message.info("Transferência cancelada com sucesso!");
    setShowModal(false);
    onClose();
  };

  const successModalOK = () => {
    setSuccessModal(false);
    onClose();
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const stepTitles = ['Colar boleto', 'Agendamento', 'Conferência'];


  const handleCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setInputCode(value);
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('Text');
    const cleanedText = pastedText.replace(/\D/g, '');
    setInputCode(cleanedText);
    e.preventDefault();
    document.execCommand('insertText', false, cleanedText);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setPriceTransfer(parseInt(numericValue, 10) || 0);

    if (priceTransfer >= 500) {
      setErrors({ amount: null });
    }
  };

  const getNextBusinessDays = (numOfDays = 3) => {
    const businessDays = [];
    let currentDate = moment();

    while (businessDays.length < numOfDays) {
      currentDate.add(1, "days");
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDays.push(currentDate.format("YYYY-MM-DD"));
      }
    }
    return businessDays;
  };

  return (
    <>
      <Modal
        className="transfer-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <h2 className="ant-modal-title">Pagar Boleto</h2>
        <div className="custom-steps">
          {stepTitles.map((title, index) => (
            <>
              <div className="step" key={index}>
                <div className={`step-icon ${currentStep > index ? 'step-finish' : currentStep === index ? 'step-active' : ''}`}>
                  {currentStep < index && <Ellipsis />}
                  {index === currentStep && <Clock />}
                  {currentStep > index && <Check />}
                </div>
                <span className={`step-title ${currentStep >= index ? 'step-active-title' : ''} ${currentStep > index ? 'step-finish' : ''}`}>
                  {title}
                </span>
              </div>
              {index < stepTitles.length - 1 && <div className="line" />}
            </>
          ))}
        </div>
        <div className="body-transfer">
          <Form form={form} layout="vertical" style={{ marginTop: 20 }}>
            {currentStep === 0 && internalCurrentStep === 0 && (
              <>
                <div className="subtitle-body">
                  <h3>Cole o código do boleto</h3>
                </div>
                <Form.Item
                  name="name"
                  label="Código do boleto"
                  rules={[{ required: true, message: 'Por favor, insira o código!' }]}
                >
                  <Input
                    value={inputCode}
                    onChange={handleCodeChange}
                    onPaste={handlePaste}
                  />
                </Form.Item>
              </>
            )}
            {currentStep === 0 && internalCurrentStep === 1 && (
              <>
                <div className="subtitle-body">
                  <h3>Qual o valor da transferência/saque?</h3>
                  <span>Saldo disponível de R$ {formatPriceInCents(bankBalance)}</span>
                </div>
                <Form.Item
                  validateStatus={errors.amount ? 'error' : ''}
                  help={errors.amount}
                  label={
                    <span>
                      Digite o valor desejado{" "}
                      <Tooltip title="Você deverá ter esse saldo disponível no dia da transferência.">
                        <InfoCircleOutlined style={{ color: "#1890ff" }} />
                      </Tooltip>
                    </span>
                  }
                  style={{ width: '100%', maxWidth: 300 }}
                  rules={[{ required: true, message: 'Por favor, insira o valor!' }]}
                >
                  <Input
                    value={formatPrice(priceTransfer / 100)}
                    onChange={handleAmountChange}
                  />
                </Form.Item>
              </>
            )}
            {currentStep === 1 && (
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Para quando deseja realizar essa transferência?"
                  name="transferOption"
                  rules={[{ required: true, message: "Por favor, selecione uma opção!" }]}
                >
                  <Radio.Group className="transfer-option" onChange={(e) => {
                    const option = e.target.value;
                    if (option === "agora") {
                      const nextBusinessDays = getNextBusinessDays(3);
                      form.setFieldsValue({ dateTransfer: nextBusinessDays[2] });
                    } else {
                      form.setFieldsValue({ dateTransfer: null });
                    }
                  }}>
                    <Radio value="agora" onChange={() => setDayTransfer('agora')}>
                      Receber em até 3 dias úteis
                    </Radio>
                    <Radio value="agendar" onChange={() => setDayTransfer('agendar')}>Agendar</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="dateTransfer"
                  label="Data de agendamento"
                  dependencies={['transferOption']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('transferOption') === 'agendar',
                      message: 'Por favor, selecione uma data!',
                    }),
                  ]}
                >
                  <div className="date-picker-container">
                    <CalendarOutlined style={{ color: "#1890ff", fontSize: "20px" }} />
                    <DatePicker
                      format="MMM DD, YYYY"
                      placeholder="Jun, 10 2024"
                      locale={ptBR}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      suffixIcon={null}
                      disabled={dayTransfer === 'agora'}
                      allowClear={false}
                      onChange={(date) => form.setFieldsValue({ dateTransfer: date })}
                    />
                  </div>
                </Form.Item>

                <Alert
                  message="Atenção: Os pagamentos são feitos apenas em dias úteis, durante o horário comercial."
                  type="warning"
                  showIcon
                  style={{ marginTop: "10px" }}
                />
              </Form>
            )}


            {currentStep === 2 && (
              <div className="resume">
                <h3>Confira as informações antes de finalizar</h3>
                <p className="subtitle">Realizando transferência para:</p>

                <div className="confirmation-card">

                  <div className="info-card">
                    <div className="info-row">
                      <span className="label">Código do boleto</span>
                      <span className="value">{form.getFieldValue('name')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Data de transferência</span>
                      <span className="value">
                        {form.getFieldValue('transferOption') === 'agora'
                          ? 'Receber em até 3 dias úteis'
                          : form.getFieldValue('dateTransfer')?.format('MMM DD, YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            )}
            <div className='container-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
              <Button style={{ marginRight: 8 }} onClick={handleCancel} danger>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleNext}>
                {currentStep === 2 ? 'Finalizar' : 'Próximo'}
              </Button>
            </div>
          </Form>
        </div>

      </Modal>
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: "orange", marginRight: "8px" }} />
            Atenção
          </span>
        }
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleConfirmOK}
          >
            Sim
          </Button>,
        ]}
        open={showModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <p>Tem certeza que deseja cancelar a solicitação de transferência?</p>
      </Modal>

      <Modal
        className='success-modal'
        width={600}
        title={
          <span className='title-modal'>
            Tudo certo! Pagamento solicitado
          </span>
        }
        footer={[
          <Button
            className='button-stop'
            key="submit"
            type="primary"
            onClick={successModalOK}
          >
            Fechar
          </Button>,
        ]}
        open={successModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <div className='container-success'>
          <Success />
          <p>Pronto! Seu pagamento foi solicitada com sucesso. </p>
          <p>O valor estará disponível em breve!</p>
        </div>
      </Modal>
    </>
  );
};

export default TransferModalBoleto;
