import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import PanelLayout from "./common/PanelLayout/PanelLayout";
import ProtectedRoute from "./common/ProtectedRoute/ProtectedRoute";
import Account from "./pages/Account/Account";
import EventPay from "./pages/EventPay/EventPay";
import Events from "./pages/Events/Events";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import NewPassword from "./pages/NewPassword/NewPassword";
import EventCheckoutDetails from "./pages/eventsList/EventCheckoutDetails/EventCheckoutDetails";
import EventCreate from "./pages/eventsList/EventCreate/EventCreate";
import EventDetails from "./pages/eventsList/EventDetails/EventDetails";
import StepsRegister from "./pages/registerPerson/StepsRegister/StepsRegister";
import CheckoutTicket from "./pages/tickets/CheckoutTicket/CheckoutTicket";
import ResponseTicket from "./pages/tickets/ResponseTicket/ResponseTicket";
import TicketSelector from "./pages/tickets/TicketSelector/TicketSelector";

function App() {
  return (
    <ConfigProvider
      locale={ptBR}
      theme={{
        components: {
          Steps: {
            colorTextBase: '#000000',
            algorithm: true
          },
          Select: {
            clearBg: "#000000"
          }
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={
            <ProtectedRoute>
              <PanelLayout><Home /></PanelLayout>
            </ProtectedRoute>} />
          <Route path="/eventos" element={
            <ProtectedRoute>
              <PanelLayout><Events /></PanelLayout>
            </ProtectedRoute>} />
          <Route path="/eventos/criar" element={
            <ProtectedRoute>
              <PanelLayout><EventCreate /></PanelLayout>
            </ProtectedRoute>} />
          <Route path="/eventos/:id?" element={<ProtectedRoute>
            <PanelLayout><EventDetails /></PanelLayout>
          </ProtectedRoute>} />
          <Route path="/evento/compras/:name?/:id?" element={<ProtectedRoute>
            <PanelLayout><EventPay /></PanelLayout>
          </ProtectedRoute>} />
          <Route path="/evento/compras/detalhe/:eventID?/:checkoutID??" element={<ProtectedRoute>
            <PanelLayout><EventCheckoutDetails /></PanelLayout>
          </ProtectedRoute>} />
          <Route path="/reset-password" element={<NewPassword />} />
          <Route path="/evento/:name?/:id?" element={<TicketSelector />} />
          <Route path="/evento/checkout/:eventID?/:id?" element={<CheckoutTicket />} />
          <Route path="/evento/pagamento/:eventID?/:checkoutID?" element={<ResponseTicket />} />
          <Route path="/conta" element={<ProtectedRoute>
            <PanelLayout><Account /></PanelLayout>
          </ProtectedRoute>} />
          <Route path="/registro" element={<StepsRegister />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;