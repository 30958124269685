import { baseSiteEndPoint, buildHeaders } from "./common";

export const apiRequestRecovery = async (jsonData) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/user/passwordrecovery/request/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData),
    });

    if (response.status === 204) {
      return {
        status: 'success',
        message: 'Requisição realizada com sucesso, sem conteúdo retornado.',
        data: null,
      };
    }
    const data = await response.json();

    return {
      status: 'success',
      message: 'Requisição realizada com sucesso.',
      data: data,
    };
  } catch (error) {
    return {
      status: 'error',
      message: 'Ocorreu um erro na requisição.',
      error: error.message || error,
    };
  }
};

export const apiPerformRecovery = async (jsonData) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/user/passwordrecovery/perform/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }

    if (response.status === 204) {
      return {
        status: 'success',
        message: 'Requisição realizada com sucesso, sem conteúdo retornado.',
        data: null,
      };
    }

    const data = await response.json();

    return {
      status: 'success',
      message: 'Requisição realizada com sucesso.',
      data: data,
    };
  } catch (error) {
    return {
      status: 'error',
      message: 'Ocorreu um erro na requisição.',
      error: error.message || error,
    };
  }
};

