import { Button, Checkbox, Divider, Form, Input, message, Switch } from "antd";
import { motion } from "motion/react";
import React, { useEffect, useState } from "react";
import { searchPostalCode } from "../../../../utils/searchPostalCode";

const CompanyForm = ({ personAddress, onNext, initialValues, setUsePersonAddress, usePersonAddress }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (usePersonAddress) {
      form.setFieldsValue({
        address: personAddress,
      });
    } else {
      form.setFieldsValue({
        address: {
          postal_code: '',
          street: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          complement: '',
        },
      });
    }
  }, [usePersonAddress, personAddress, form]);

  // Funções de validação
  const validateEmail = (rule, value) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("E-mail inválido! Insira um e-mail no formato correto.");
  };

  const validateCNPJ = (rule, value) => {
    const regex = /^\d{14}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("CNPJ inválido! O CNPJ deve conter 14 dígitos numéricos.");
  };

  const validatePhone = (rule, value) => {
    const regex = /^\d{11}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Telefone inválido! O telefone deve conter 11 dígitos numéricos.");
  };


  const handleFinish = (values) => {
    onNext({
      name: values.name,
      tax_id: values.tax_id,
      phone: values.phone,
      email: values.email,
      address: values.address,
    });
  };

  const handleOnlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/\D/g, '');
  };

  const [isComplementDisabled, setIsComplementDisabled] = useState(false);
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsComplementDisabled(checked);

    if (checked) {
      form.setFieldsValue({
        address: { complement: 'Sem complemento' },
      });
    }
  };

  const handlePostalCodeChange = (value) => {
    searchPostalCode(value, (data) => {
      if (data && !data.erro) {
        form.setFieldsValue({
          address: {
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            complement: '',
          },
        });
      } else {
        message.error("CEP não encontrado ou inválido.");
      }
    });
  };

  return (
    <motion.div
      className="CompanyForm"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
    >
      <h2>Dados da Empresa</h2>
      <Divider />
      <Form form={form} validateTrigger="onBlur" layout="vertical" onFinish={handleFinish}>
        <div className="container-inputs-group">
          <div className="left">
            <Form.Item
              name="name"
              label="Nome da Empresa"
              rules={[{ required: true, message: "Insira o nome da empresa!" }]}
            >
              <Input placeholder="Nome da Empresa" />
            </Form.Item>
            <Form.Item
              name="tax_id"
              label="CNPJ"
              rules={[
                { required: true, message: "Insira o CNPJ!" },
                { validator: validateCNPJ },
              ]}
            >
              <Input
                placeholder="CNPJ"
                onChange={handleOnlyNumbers}
              />
            </Form.Item>
          </div>
          <div className="right">
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { required: true, message: "Insira o e-mail da empresa!" },
                { validator: validateEmail },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Telefone"
              rules={[
                { required: true, message: "Insira o telefone da empresa!" },
                { validator: validatePhone },
              ]}
            >
              <Input
                placeholder="Telefone"
                onChange={handleOnlyNumbers}
              />
            </Form.Item>
          </div>
        </div>

        {/* Address form */}
        <div className="event-form-location">
          <div className="title-container">
            <p>Endereço:</p>
          </div>
          <Form.Item
            style={{ marginBottom: 0 }}
          >
            <div className="switch-map">
              <label className="label-switch">Copiar Endereço da Pessoa</label>
              <Switch
                checked={usePersonAddress}
                onChange={() => setUsePersonAddress(!usePersonAddress)}
              />
            </div>
          </Form.Item>
          <div className="distribution-three">
            <Form.Item
              label="CEP"
              name={['address', 'postal_code']}
              rules={[{ required: true, message: "Informe o CEP!" }]}
            >
              <Input onBlur={(e) => handlePostalCodeChange(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Rua"
              name={['address', 'street']}
              rules={[{ required: true, message: "Informe a rua!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Número"
              name={['address', 'number']}
              rules={[{ required: true, message: "Informe o número!" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="distribution-two">
            <Form.Item
              label="Bairro"
              name={['address', 'neighborhood']}
              rules={[{ required: true, message: "Informe o bairro!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Cidade"
              name={['address', 'city']}
              rules={[{ required: true, message: "Informe a cidade!" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="distribution-finish">
            <Form.Item
              label="Estado"
              name={['address', 'state']}
              rules={[{ required: true, message: "Informe o estado!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Complemento"
              name={['address', 'complement']}
              rules={[{ required: true, message: "Informe o complemento!" }]}
            >
              <Input disabled={isComplementDisabled} />
            </Form.Item>
            <Checkbox className="edit-complement" onChange={handleCheckboxChange} >
              <p>Sem complemento</p>
            </Checkbox>
          </div>
        </div>

        <Form.Item>
          <div className="container-buttons">
            <Button type="primary" htmlType="submit" className="button-form">
              Próximo
            </Button>
          </div>
        </Form.Item>
      </Form>
    </motion.div>
  );
};

export default CompanyForm;
