import { baseSiteEndPoint, buildHeaders, getSellerID } from "./common";
import { authorizedFetch } from "./login";

export const apiTransferBankAccount = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/uniconta/seller/${getSellerID()}/virtualaccounts/${getSellerID()}/transfer/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiBankSlip = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/uniconta/seller/${getSellerID()}/virtualaccounts/${getSellerID()}/pay/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
