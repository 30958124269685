export const bankChoices = [
  { id: '1', name: "1 - Banco Do Brasil S.A (BB)" },
  { id: '3', name: "3 - Banco Da Amazônia S.A." },
  { id: '4', name: "4 - Banco Do Nordeste Do Brasil S.A." },
  { id: '7', name: "7 - BNDES (Banco Nacional Do Desenvolvimento Social)" },
  { id: '10', name: "10 - CREDICOAMO CRÉDITO RURAL COOPERATIVA" },
  { id: '11', name: "11 - C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)" },
  { id: '12', name: "12 - Banco Inbursa" },
  { id: '14', name: "14 - Natixis Brasil S.A." },
  { id: '14', name: "14 - STATE STREET BRASIL S.A. – BANCO COMERCIAL" },
  { id: '15', name: "15 - UBS Brasil Cctvm S.A." },
  { id: '16', name: "16 - Ccm Desp Trâns Sc E Rs" },
  { id: '16', name: "16 - COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI" },
  { id: '17', name: "17 - Bny Mellon Banco S.A." },
  { id: '18', name: "18 - Banco Tricury S.A." },
  { id: '21', name: "21 - Banco Banestes S.A." },
  { id: '24', name: "24 - Banco Bandepe S.A." },
  { id: '25', name: "25 - Banco Alfa S.A." },
  { id: '29', name: "29 - Banco Itaú Consignado S.A." },
  { id: '33', name: "33 - Banco Santander Brasil S.A." },
  { id: '36', name: "36 - Banco Bradesco BBI S.A." },
  { id: '37', name: "37 - Banco Do Estado Do Pará S.A." },
  { id: '40', name: "40 - Banco Cargill S.A." },
  { id: '41', name: "41 - Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A." },
  { id: '47', name: "47 - Banco do Estado de Sergipe S.A." },
  { id: '60', name: "60 - Confidence Cc S.A." },
  { id: '62', name: "62 - Hipercard Banco Múltiplo S.A." },
  { id: '63', name: "63 - Banco Bradescard" },
  { id: '64', name: "64 - Goldman Sachs Do Brasil Bm S.A." },
  { id: '65', name: "65 - Banco Andbank S.A." },
  { id: '66', name: "66 - Banco Morgan Stanley S.A." },
  { id: '69', name: "69 - Banco Crefisa S.A." },
  { id: '70', name: "70 - BRB – Banco De Brasília S.A." },
  { id: '74', name: "74 - Banco J. Safra S.A." },
  { id: '75', name: "75 - Bco Abn Amro S.A." },
  { id: '76', name: "76 - Banco KDB Brasil S.A." },
  { id: '77', name: "77 - Banco Inter S.A." },
  { id: '78', name: "78 - Haitong Bi Do Brasil S.A." },
  { id: '79', name: "79 - Banco Original Do Agronegócio S.A." },
  { id: '80', name: "80 - B&T Cc Ltda" },
  { id: '81', name: "81 - Banco Seguro S.A." },
  { id: '81', name: "81 - Bbn Banco Brasileiro De Negocios S.A." },
  { id: '82', name: "82 - Banco Topázio S.A." },
  { id: '83', name: "83 - Banco Da China Brasil S.A." },
  { id: '84', name: "84 - UNIPRIME NORTE DO PARANÁ – COOPERATIVA DE CRÉDITO LTDA" },
  { id: '84', name: "84 - Uniprime Norte Do Paraná" },
  { id: '85', name: "85 - Cooperativa Central de Créditos – Ailos" },
  { id: '88', name: "88 - BANCO RANDON S.A." },
  { id: '89', name: "89 - Ccr Reg Mogiana" },
  { id: '89', name: "89 - CREDISAN COOPERATIVA DE CRÉDITO" },
  { id: '91', name: "91 - Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S" },
  { id: '91', name: "91 - Unicred Central Rs" },
  { id: '92', name: "92 - BRK S.A." },
  { id: '93', name: "93 - PóloCred Scmepp Ltda" },
  { id: '94', name: "94 - Banco Finaxis" },
  { id: '95', name: "95 - Banco Confidence De Câmbio S.A." },
  { id: '95', name: "95 - Travelex Banco de Câmbio S.A." },
  { id: '96', name: "96 - Banco B3 S.A." },
  { id: '97', name: "97 - Ccc Noroeste Brasileiro Ltda" },
  { id: '97', name: "97 - Credisis – Central de Cooperativas de Crédito Ltda." },
  { id: '98', name: "98 - Credialiança Ccr" },
  { id: '99', name: "99 - Uniprime Central Ccc Ltda" },
  { id: '100', name: "100 - Planner Corretora De Valores S.A." },
  { id: '101', name: "101 - Renascença Dtvm Ltda" },
  { id: '102', name: "102 - XP Investimentos S.A." },
  { id: '104', name: "104 - Caixa Econômica Federal (CEF)" },
  { id: '105', name: "105 - Lecca Cfi S.A." },
  { id: '107', name: "107 - Banco Bocom BBM S.A." },
  { id: '108', name: "108 - Portocred S.A." },
  { id: '111', name: "111 - Banco Oliveira Trust Dtvm S.A." },
  { id: '113', name: "113 - Magliano S.A." },
  { id: '114', name: "114 - Central Cooperativa De Crédito no Estado do Espírito Santo" },
  { id: '117', name: "117 - Advanced Cc Ltda" },
  { id: '118', name: "118 - Standard Chartered Bi S.A." },
  { id: '119', name: "119 - Banco Western Union do Brasil S.A." },
  { id: '120', name: "120 - Banco Rodobens S.A." },
  { id: '121', name: "121 - Banco Agibank S.A." },
  { id: '122', name: "122 - Banco Bradesco BERJ S.A." },
  { id: '124', name: "124 - Banco Woori Bank Do Brasil S.A." },
  { id: '125', name: "125 - Brasil Plural S.A Banco" },
  { id: '126', name: "126 - BR Partners Banco de Investimento S.A." },
  { id: '127', name: "127 - Codepe Cvc S.A." },
  { id: '128', name: "128 - Ms Bank S.A Banco De Câmbio" },
  { id: '129', name: "129 - UBS Brasil Bi S.A." },
  { id: '130', name: "130 - Caruana Scfi" },
  { id: '131', name: "131 - Tullett Prebon Brasil Cvc Ltda" },
  { id: '132', name: "132 - ICBC do Brasil Bm S.A." },
  { id: '133', name: "133 - Cresol Confederação" },
  { id: '134', name: "134 - BGC Liquidez Dtvm Ltda" },
  { id: '136', name: "136 - Unicred Cooperativa LTDA" },
  { id: '137', name: "137 - Multimoney Cc Ltda" },
  { id: '138', name: "138 - Get Money Cc Ltda" },
  { id: '139', name: "139 - Intesa Sanpaolo Brasil S.A." },
  { id: '140', name: "140 - Easynvest – Título Cv S.A." },
  { id: '142', name: "142 - Broker Brasil Cc Ltda" },
  { id: '143', name: "143 - Treviso Cc S.A." },
  { id: '144', name: "144 - Bexs Banco De Cambio S.A." },
  { id: '145', name: "145 - Levycam Ccv Ltda" },
  { id: '146', name: "146 - Guitta Cc Ltda" },
  { id: '149', name: "149 - Facta S.A. Cfi" },
  { id: '157', name: "157 - Icap Do Brasil Ctvm Ltda" },
  { id: '159', name: "159 - Casa do Crédito S.A." },
  { id: '163', name: "163 - Commerzbank Brasil S.A Banco Múltiplo" },
  { id: '169', name: "169 - Banco Olé Bonsucesso Consignado S.A." },
  { id: '172', name: "172 - Albatross Ccv S.A." },
  { id: '173', name: "173 - BRL Trust Dtvm Sa" },
  { id: '174', name: "174 - PEFISA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
  { id: '174', name: "174 - Pernambucanas Financ S.A." },
  { id: '177', name: "177 - Guide Investimentos S.A. Corretora de Valores" },
  { id: '180', name: "180 - Cm Capital Markets Cctvm Ltda" },
  { id: '182', name: "182 - Dacasa Financeira S/A" },
  { id: '183', name: "183 - Socred S.A." },
  { id: '184', name: "184 - Banco Itaú BBA S.A." },
  { id: '188', name: "188 - Ativa S.A Investimentos" },
  { id: '189', name: "189 - Hs Financeira" },
  { id: '190', name: "190 - Servicoop" },
  { id: '191', name: "191 - Nova Futura Ctvm Ltda" },
  { id: '194', name: "194 - Parmetal Dtvm Ltda" },
  { id: '196', name: "196 - Banco Fair Cc S.A." },
  { id: '197', name: "197 - Stone Pagamentos S.A." },
  { id: '204', name: "204 - Banco Bradesco Cartoes S.A." },
  { id: '208', name: "208 - Banco BTG Pactual S.A." },
  { id: '212', name: "212 - Banco Original S.A." },
  { id: '213', name: "213 - Bco Arbi S.A." },
  { id: '217', name: "217 - Banco John Deere S.A." },
  { id: '218', name: "218 - Banco Bs2 S.A." },
  { id: '222', name: "222 - Banco Crédit Agricole Br S.A." },
  { id: '224', name: "224 - Banco Fibra S.A." },
  { id: '233', name: "233 - Banco Cifra S.A." },
  { id: '237', name: "237 - Bradesco S.A." },
  { id: '237', name: "237 - Next Bank (Mesmo Código Do Bradesco)" },
  { id: '241', name: "241 - Banco Classico S.A." },
  { id: '243', name: "243 - Banco Máxima S.A." },
  { id: '246', name: "246 - Banco Abc Brasil S.A." },
  { id: '249', name: "249 - Banco Investcred Unibanco S.A." },
  { id: '250', name: "250 - Banco Bcv" },
  { id: '253', name: "253 - Bexs Cc S.A." },
  { id: '254', name: "254 - Paraná Banco S.A." },
  { id: '259', name: "259 - MONEYCORP BANCO DE CÂMBIO S.A." },
  { id: '260', name: "260 - Nu Pagamentos S.A (Nubank)" },
  { id: '265', name: "265 - Banco Fator S.A." },
  { id: '266', name: "266 - Banco Cedula S.A." },
  { id: '268', name: "268 - Barigui Companhia Hipotecária" },
  { id: '269', name: "269 - Hsbc Banco De Investimento" },
  { id: '270', name: "270 - Sagitur Cc Ltda" },
  { id: '271', name: "271 - Ib Cctvm Ltda" },
  { id: '272', name: "272 - AGK Corretora de Câmbio S.A." },
  { id: '273', name: "273 - Ccr De São Miguel Do Oeste" },
  { id: '274', name: "274 - MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT" },
  { id: '276', name: "276 - Senff S.A." },
  { id: '278', name: "278 - Genial Investimentos Cvm S.A." },
  { id: '279', name: "279 - Ccr De Primavera Do Leste" },
  { id: '280', name: "280 - Avista S.A." },
  { id: '281', name: "281 - Cooperativa de Crédito Rural Coopavel" },
  { id: '283', name: "283 - RB Capital Investimentos Dtvm Ltda" },
  { id: '285', name: "285 - Frente Cc Ltda" },
  { id: '286', name: "286 - Cooperativa de Crédito Rural de De Ouro" },
  { id: '288', name: "288 - Carol Dtvm Ltda" },
  { id: '289', name: "289 - DECYSEO CORRETORA DE CAMBIO LTDA." },
  { id: '290', name: "290 - PagSeguro Internet S.A." },
  { id: '292', name: "292 - BS2 Distribuidora De Títulos E Investimentos" },
  { id: '293', name: "293 - Lastro Rdv Dtvm Ltda" },
  { id: '296', name: "296 - VISION S.A. CORRETORA DE CAMBIO" },
  { id: '298', name: "298 - Vip’s Cc Ltda" },
  { id: '299', name: "299 - SOROCRED CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
  { id: '300', name: "300 - Banco La Nacion Argentina" },
  { id: '301', name: "301 - BPP Instituição De Pagamentos S.A." },
  { id: '306', name: "306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS LTDA." },
  { id: '307', name: "307 - Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda." },
  { id: '309', name: "309 - CAMBIONET CORRETORA DE CÂMBIO LTDA." },
  { id: '310', name: "310 - VORTX Dtvm Ltda" },
  { id: '313', name: "313 - AMAZÔNIA CORRETORA DE CÂMBIO LTDA." },
  { id: '315', name: "315 - PI Distribuidora de Títulos e Valores Mobiliários S.A." },
  { id: '318', name: "318 - Banco BMG S.A." },
  { id: '319', name: "319 - OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
  { id: '320', name: "320 - China Construction Bank – Ccb Brasil S.A." },
  { id: '321', name: "321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT" },
  { id: '322', name: "322 - Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz" },
  { id: '323', name: "323 - Mercado Pago – Conta Do Mercado Livre" },
  { id: '324', name: "324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '325', name: "325 - Órama Distribuidora de Títulos e Valores Mobiliários S.A." },
  { id: '326', name: "326 - PARATI – CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
  { id: '329', name: "329 - QI Sociedade de Crédito Direto S.A." },
  { id: '330', name: "330 - Banco Bari de Investimentos e Financiamentos S.A." },
  { id: '331', name: "331 - Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A." },
  { id: '332', name: "332 - Acesso Soluções de Pagamento S.A." },
  { id: '335', name: "335 - Banco Digio S.A." },
  { id: '336', name: "336 - Banco C6 S.A – C6 Bank" },
  { id: '340', name: "340 - Super Pagamentos S/A (Superdital)" },
  { id: '341', name: "341 - Itaú Unibanco S.A." },
  { id: '342', name: "342 - Creditas Sociedade de Crédito Direto S.A." },
  { id: '343', name: "343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA." },
  { id: '348', name: "348 - Banco XP S/A" },
  { id: '349', name: "349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
  { id: '350', name: "350 - Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce" },
  { id: '352', name: "352 - TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
  { id: '354', name: "354 - NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES" },
  { id: '355', name: "355 - ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '359', name: "359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A" },
  { id: '360', name: "360 - TRINUS Capital Distribuidora De Títulos E Valores Mobiliários S.A." },
  { id: '362', name: "362 - CIELO S.A." },
  { id: '363', name: "363 - SOCOPA SOCIEDADE CORRETORA PAULISTA S.A." },
  { id: '364', name: "364 - GERENCIANET S.A." },
  { id: '365', name: "365 - SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS" },
  { id: '366', name: "366 - Banco Societe Generale Brasil" },
  { id: '367', name: "367 - VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
  { id: '368', name: "368 - Banco CSF S.A." },
  { id: '370', name: "370 - Banco Mizuho S.A." },
  { id: '371', name: "371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA." },
  { id: '373', name: "373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A." },
  { id: '374', name: "374 - REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
  { id: '376', name: "376 - Banco J.P. Morgan S.A." },
  { id: '377', name: "377 - MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA" },
  { id: '378', name: "378 - BBC LEASING S.A. – Arrendamento Mercantil" },
  { id: '379', name: "379 - COOPERFORTE – Cooperativa De Economia E Crédito Mútuo Dos Funcionários De Instit" },
  { id: '380', name: "380 - PICPAY SERVICOS S.A." },
  { id: '381', name: "381 - BANCO MERCEDES-BENZ DO BRASIL S.A." },
  { id: '382', name: "382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L" },
  { id: '383', name: "383 - BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA." },
  { id: '384', name: "384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO" },
  { id: '387', name: "387 - Banco Toyota do Brasil S.A." },
  { id: '389', name: "389 - Banco Mercantil Do Brasil S.A." },
  { id: '389', name: "389 - Banco Mercantil Do Brasil S.A." },
  { id: '390', name: "390 - BANCO GM S.A." },
  { id: '391', name: "391 - COOPERATIVA DE CRÉDITO RURAL DE IBIAM – SULCREDI/IBIAM" },
  { id: '393', name: "393 - Banco Volkswagen S.A." },
  { id: '394', name: "394 - Banco Bradesco Financiamentos S.A." },
  { id: '396', name: "396 - HUB PAGAMENTOS S.A." },
  { id: '397', name: "397 - LISTO SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '399', name: "399 - Kirton Bank S.A. – Banco Múltiplo" },
  { id: '403', name: "403 - CORA SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '404', name: "404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '408', name: "408 - BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A." },
  { id: '412', name: "412 - Banco Capital S.A." },
  { id: '422', name: "422 - Banco Safra S.A." },
  { id: '456', name: "456 - Banco MUFG Brasil S.A." },
  { id: '464', name: "464 - Banco Sumitomo Mitsui Brasil S.A." },
  { id: '473', name: "473 - Banco Caixa Geral Brasil S.A." },
  { id: '477', name: "477 - Citibank N.A." },
  { id: '479', name: "479 - Banco ItauBank S.A." },
  { id: '487', name: "487 - Deutsche Bank S.A (Banco Alemão)" },
  { id: '488', name: "488 - Jpmorgan Chase Bank" },
  { id: '492', name: "492 - Ing Bank N.V." },
  { id: '494', name: "494 - Banco Rep Oriental Uruguay" },
  { id: '495', name: "495 - La Provincia Buenos Aires Banco" },
  { id: '505', name: "505 - Banco Credit Suisse (Brl) S.A." },
  { id: '545', name: "545 - Senso Ccvm S.A." },
  { id: '600', name: "600 - Banco Luso Brasileiro S.A." },
  { id: '604', name: "604 - Banco Industrial Do Brasil S.A." },
  { id: '610', name: "610 - Banco VR S.A." },
  { id: '611', name: "611 - Banco Paulista" },
  { id: '612', name: "612 - Banco Guanabara S.A." },
  { id: '613', name: "613 - Omni Banco S.A." },
  { id: '623', name: "623 - Banco Pan S.A." },
  { id: '626', name: "626 - BANCO C6 CONSIGNADO S.A." },
  { id: '626', name: "626 - Banco Ficsa S.A." },
  { id: '630', name: "630 - Banco Intercap S.A." },
  { id: '630', name: "630 - Banco Smartbank S.A." },
  { id: '633', name: "633 - Banco Rendimento S.A." },
  { id: '634', name: "634 - Banco Triangulo S.A (Banco Triângulo)" },
  { id: '637', name: "637 - Banco Sofisa S.A (Sofisa Direto)" },
  { id: '641', name: "641 - Banco Alvorada S.A." },
  { id: '643', name: "643 - Banco Modal S.A." },
  { id: '644', name: "644 - Banco UBB" },
  { id: '645', name: "645 - Banco Porto Real" },
  { id: '707', name: "707 - Banco Daycoval S.A." },
  { id: '712', name: "712 - Banco Ourinvest S.A." },
  { id: '739', name: "739 - Banco Cetelem S.A." },
  { id: '741', name: "741 - Banco Ribeirão Preto S.A." },
  { id: '743', name: "743 - Banco Semear S.A." },
  { id: '745', name: "745 - Banco Citibank S.A." },
  { id: '746', name: "746 - Banco Modal S.A." },
  { id: '747', name: "747 - Banco Rabobank Internacional Do Brasil S.A." },
  { id: '748', name: "748 - Banco Cooperativa Sicredi S.A." },
  { id: '751', name: "751 - Scotiabank Brasil" },
  { id: '752', name: "752 - Banco BNP Paribas Brasil S.A." },
  { id: '753', name: "753 - Novo Banco Continental S.A Bm" },
  { id: '754', name: "754 - Banco Sistema S.A." },
  { id: '755', name: "755 - Bank of America Merrill Lynch Banco Múltiplo S.A." },
  { id: '755', name: "755 - Bofa Merrill Lynch Bm S.A." },
  { id: '756', name: "756 - Bancoob – Banco Cooperativo Do Brasil S.A." },
  { id: '757', name: "757 - Banco Keb Hana Do Brasil S.A." }
];